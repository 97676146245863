import { createReducer, isAnyOf, PayloadAction } from '@reduxjs/toolkit';

import {
  confirmUser,
  signInEmail,
  signUpGoogle,
  signInApple,
  signInGoogle,
  signUpApple,
  signUpEmail,
  signInUser,
  signOutUser,
  refetchUser,
  resendConfirmationCode,
  resetSignUpCredentials,
  updateUser,
  fetchUserSubscriptions,
  fetchUserTeams,
  resetErrors,
  resetUserHasSignOut,
} from './actions';

import { User, UserSubscription, UserTeam } from '../../types/user';
import { ReduxState } from '../../types/redux-state';
import {
  removeLocalStorageData,
  setLocalStorageData,
} from '../../utils/localstorage';

interface UserState extends ReduxState {
  user: User;
  // Key and token are for signUp user
  key: string;
  token: string;
  // This will be used for the dropdown menu
  userTeams: UserTeam[];
  userSubscriptions: UserSubscription[];
  // To prevent signing out to be consider as error in authlayout
  userHasSignOut: boolean;
}

const initialState: UserState = {
  user: {
    accessToken: '',
    refreshToken: '',
    id: '',
    email: '',
    firstName: '',
    lastName: '',
    photo: '',
    products: [],
    member: [],
    licenceType: '',
  },
  userSubscriptions: [],
  key: '',
  token: '',
  userTeams: [],
  userHasSignOut: false,
  isLoading: false,
  errors: [],
};

const setUser = (state: UserState, { payload }: PayloadAction<User>) => {
  state.userHasSignOut = false;
  state.user = payload;

  setLocalStorageData('accessToken', payload.accessToken);
};

const setSignUpCredentials = (
  state: UserState,
  { payload }: PayloadAction<UserState>
) => {
  state.key = payload.key;
  state.token = payload.token;
};

export const userReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(resetErrors, (state) => {
      state.errors = [];
    })
    .addCase(signInUser, (state, { payload }) => {
      state.user = payload;
    })
    .addCase(signOutUser, (state) => {
      state.userHasSignOut = true;
      state.user = initialState.user;

      removeLocalStorageData('state');
      removeLocalStorageData('hasAskToJoin');
      removeLocalStorageData('accessToken');
    })
    .addCase(resetUserHasSignOut, (state) => {
      state.userHasSignOut = false;
    })
    .addCase(resetSignUpCredentials, (state) => {
      state.key = '';
      state.token = '';
    })
    .addCase(refetchUser.fulfilled, (state, action) => {
      setUser(state, action);
    })
    .addCase(signInEmail.fulfilled, (state, action) => {
      setUser(state, action);
    })
    .addCase(signUpEmail.fulfilled, (state, action) => {
      setSignUpCredentials(state, action);
    })
    .addCase(confirmUser.fulfilled, (state, action) => {
      setUser(state, action);
    })
    .addCase(fetchUserSubscriptions.fulfilled, (state, { payload }) => {
      state.userSubscriptions = payload;
    })
    .addCase(fetchUserTeams.fulfilled, (state, { payload }) => {
      state.userTeams = payload;
    })
    .addMatcher(
      isAnyOf(
        signInEmail.pending,
        signInGoogle.pending,
        signInApple.pending,
        signUpEmail.pending,
        signUpGoogle.pending,
        signUpApple.pending,
        confirmUser.pending,
        resendConfirmationCode.pending,
        refetchUser.pending,
        fetchUserSubscriptions.pending,
        fetchUserTeams.pending,
        updateUser.pending
      ),
      (state) => {
        state.isLoading = true;
        state.errors = [];
      }
    )
    .addMatcher(
      isAnyOf(
        signInEmail.fulfilled,
        signInGoogle.fulfilled,
        signInApple.fulfilled,
        signUpEmail.fulfilled,
        signUpGoogle.fulfilled,
        signUpApple.fulfilled,
        confirmUser.fulfilled,
        resendConfirmationCode.fulfilled,
        refetchUser.fulfilled,
        fetchUserSubscriptions.fulfilled,
        fetchUserTeams.fulfilled,
        updateUser.fulfilled
      ),
      (state) => {
        state.isLoading = false;
      }
    )
    .addMatcher(
      isAnyOf(
        signInGoogle.fulfilled,
        signInApple.fulfilled,
        signUpGoogle.fulfilled,
        signUpApple.fulfilled
      ),
      (state, action) => {
        // The implementation of signUp and signIn with third-party apps like Apple and Google are the following:
        // 1. If they have an existing account with us, they will be login immediately
        // 2. If they don't, they will have to sign up first.
        if (action.payload.key) {
          setSignUpCredentials(state, action);
        } else {
          setUser(state, action);
        }
      }
    )
    .addMatcher(
      isAnyOf(
        fetchUserSubscriptions.rejected,
        fetchUserTeams.rejected,
        signInEmail.rejected,
        signInGoogle.rejected,
        signInApple.rejected,
        signUpEmail.rejected,
        signUpGoogle.rejected,
        signUpApple.rejected,
        confirmUser.rejected,
        resendConfirmationCode.rejected,
        refetchUser.rejected,
        updateUser.rejected
      ),
      (state, { payload }) => {
        state.errors = payload as string[];
        state.isLoading = false;
      }
    );
});

export default userReducer;
