export enum DateRangeEnum {
  TODAY = 'today',
  SEVEN_DAYS = 'seven-days',
  THIRTY_DAYS = 'thirty-days',
  NINETY_DAYS = 'ninety-days',
  CUSTOM = 'custom',
}

export interface DateRangeProp {
  name: DateRangeEnum;
  sx: object;
  onClick: (value: string, event?: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface PageButtonProp {
  name: string;
  sx: object;
  onClick: (page: string) => void;
}
